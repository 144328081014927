<template>
  <div class="CreatorVideoCoverEdit file-upmyloader" ref="modal" :settings="{ opened: true }" @close="modalClose">
    <div :class="`newUserFile ${isEdit && '__edit'}`" dpadding>
      <form id="user-file" :class="`options newUserFileForm ${hasFile ? 'hasFile' : ''}`" :key="formKey" v-on:submit.prevent>
        <div class="editFilePreview" v-if="isEdit">
          <UserNewFilePreview :file="isEdit" :fileType="mediaType(isEdit)" :isEdit="isEdit" />
        </div>

        <div :class="`newFilePreview`" v-else-if="hasFile">
          <UserNewFilePreview :file="tmpFile" :fileType="fileType" @thumbnail="(file) => (thumbnail = file)" />
        </div>

        <div class="newFileOptions">
          <Input
            name="video"
            :label="$locale['file']"
            type="file"
            accept="image/*, video/*"
            @change="isValidFile"
            v-if="!isEdit"
            _class="defaultInput activeInput"
          />

          <Spacer num="2" />

          <UserFileFor
            :enable="['public']"
            name="availableFor"
            :label="$locale['file_for']"
            @select="(value) => (availableFor = value)"
            :value="availableFor"
          />

          <Spacer num="1" />

          <div class="filePriceBlock" v-if="availableFor === 'purchase'" bold>
            <Input name="price" :label="`${$locale['price']} / ${$locale['credits']}`" type="number" :value="isEdit ? isEdit.price : 1" />
          </div>

          <Spacer num="2" />

          <Confirm
            v-if="!$loading"
            :confirm="{ accept: { label: $locale['accept'] }, cancel: { label: $locale['cancel'] } }"
            @cancel="modalClose"
            @accept="saveFile"
          />
        </div>
      </form>
    </div>
  </div>
</template>

<script>
export default {
  props: ["modal"],
  components: {
    UserFileFor: () => import("../user/UserFileFor.vue"),
    UserNewFilePreview: () => import("../user/UserNewFilePreview.vue"),
  },
  data: function() {
    return {
      message: "",
      formKey: 0,
      hasFile: false,
      hasName: false,
      availableFor: "public",
      fileType: "",
      tmpFile: {},
      thumbnail: "",
      myloader: false,
      axios: {
        headers: {
          "use-form": "false",
          files: {},
          _id: null,
        },
      },
    };
  },
  methods: {
    modalClose: function() {
      this.$store.state.user.newFile = null;
      this.$store.state.user.fileEdit = null;
      if (Go.is(this.modal && this.modal.close, "Function")) {
        this.modal.close();
      }
    },
    saveFile: async function() {
      this.myloader = await this.loader();
      this.sendFile();
    },
    sendFile: async function() {
      this.isLoading({ state: true, lockapp: true });
      try {
        const formData = new FormData(document.querySelector("#user-file"));

        if (this.isEdit) {
          this.axios.headers.isedit = this.isEdit._id;
          this.axios.headers["use-form"] = true;
          formData.append("isedit", this.isEdit._id);
        } else if (!this.hasFile) {
          this.isLoading({ state: false, lockapp: false });
          await Go.sleep(APP_TRANSITION_TIME);

          this.myloader.close(() => {
            this.showMessage({ title: "Error", message: this.$locale["select_file"] });
          });

          return;
        }

        if (this.thumbnail) {
          const thumbnail = this.base64ToFile(this.thumbnail, "thumbnail.png");
          formData.append("thumbnail", thumbnail);
        }

        const req = await this.$http.post(`${this.$apiHost}creator/upload-video-cover`, formData, this.axios);
        this.saveFileEnd(req.data);
      } catch (error) {
        this.myloader.close(() => {
          this.showMessage({ title: "Error", message: error });
        });
      }
      this.isLoading(false);
    },
    saveFileEnd: async function(data) {
      const { success, message, file, total } = data;

      if (!success) {
        this.myloader.close(() => {
          this.showMessage({ title: "Error", message });
        });
        return;
      }

      if (this.isEdit && success) {
        this.message = message;
        this.$store.state.user.fileEdit = file;
        this.myloader.close(() => {
          if (message) this.showMessage(data);
        });
        return;
      }

      if (success) {
        this.$store.state.user.files.items.unshift(file);
        this.$store.state.user.files.total = total;
        this.formKey++;
        this.modalClose();
        this.myloader.close(() => {
          if (message) this.showMessage(data);
        });
      }
    },
    isValidFile: async function({ target: { files } }) {
      if (!files.length) {
        return;
      }

      const file = files[0];
      const fileType = file.type.split("/")[0];

      if (fileType !== "image" && fileType !== "video") {
        this.modal.close(() => {
          this.showMessage({ title: "Error", message: this.$locale["invalid_file_type"] });
        });
        this.formKey++;
        return;
      }

      this.tmpFile = null;

      if (fileType === "image") {
        this.tmpFile = await this.$image.read(file);
      }

      if (fileType === "video") {
        this.tmpFile = await this.$video.read(file);
      }

      this.fileType = fileType;
      this.hasFile = true;
    },
    hasChanged: function({ target: { value } }) {
      this.hasName = !!value;
      this.message = "";
    },
  },
  mounted: function() {
    if (this.isEdit) {
      this.availableFor = this.isEdit.availableFor;
    }
  },
  computed: {
    progress: function() {
      return this.$store.state.socket.progress;
    },
    isEdit: function() {
      return this.$store.state.user.fileEdit;
    },
  },
};
</script>

<style lang="scss">
.file-upmyloader {
  &.modal .modal-card {
    width: auto;
    max-width: $tablet_width;
  }

  .newUserFile {
    min-width: 300px;
  }

  .filePriceBlock input {
    font-weight: bold;
    text-align: center !important;
  }

  .newFilePreview {
    img,
    video {
      border-radius: $mpadding;
    }
    .imageCover {
      border-radius: $mpadding/2;
    }
  }

  .newFileOptions {
    margin: $mpadding * 2 0 0 0;
    .block-input:first-child {
      margin: 0 0 0 0;
    }
  }

  @include inputsText() {
    min-height: $mpadding * 3;
  }

  @include screen($desktop_width) {
    .newFileOptions {
      margin: 0 0 0 0;
      display: flex;
      flex-direction: column;
      .confirm {
        margin: auto 0 0 0;
      }
    }

    .newUserFileForm.hasFile {
      display: flex;
      gap: $mpadding;
    }

    .newFilePreview,
    .newFileOptions {
      flex: 0.5;
    }
  }
}
</style>
